import { CACHE_KEY_PPREFIX } from "../getUser"

export class LogOutError extends Error {
    constructor(m: string) {
        super(m)
        for (let key in localStorage) {
            if (key.includes(CACHE_KEY_PPREFIX)) {
                localStorage.removeItem(key)
            }
         }
    }
}

export default LogOutError
